<template>
  <div id="page-ponta" v-if="coupon">
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section first-section">
        <div class="alert alert-danger t15" v-if="message"> {{message}}</div>
        <div v-if="coupon.isValid">
          <p class="t16">{{$t('coupon_attach.got_coupon')}} </p>
          <p><img v-bind:src="getCouponImage(coupon)"  alt="coupon"/></p>
        </div>
        <div>
        </div>
        <div class="text-center">
          <button class="btn btn-success t16" @click="redeem" :disabled="!coupon.isValid">{{ $t('btn_active')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponAttachment',
  data () {
    return {
      message: '',
      coupon: null
    }
  },
  props: {
    code: {
      type: String,
      required: true,
      default: ''
    }
  },
  mounted () {
    if (!this.$auth.check()) {
      this.$router.push({name: 'login', params: {method: 'couponattach'}, query: { callback: '1', code: this.code }})
      return false
    }
    this.getCouponInfo()
  },
  methods: {
    redeem () {
      if (!this.code) {
        return false
      }

      var vm = this
      vm.message = ''
      vm.axios.apiCoupon.getRedeemCoupon({code: this.code, isShowNotify: false}, (response) => {
        if (response.data.status) {
          vm.$notify({
            type: 'success',
            title: vm.$t('coupon.redeem_success')
          })
          vm.redirectToMenuPage(vm.$const.HEADER_MENU.COUPON)
        } else {
          vm.message = vm.$t('coupon_attach.message_error')
        }
      }, (error) => {
        let mess = error.error.message
        vm.message = mess
      })
    },
    getCouponInfo () {
      if (!this.code) {
        return false
      }
      this.message = ''
      var vm = this
      this.axios.apiCoupon.getRedeemCouponInfo({code: this.code, isShowNotify: false}, (response) => {
        let item = response.data.data.item
        if (response.data.status) {
          vm.coupon = item
          // vm.coupon.img = item.image != null && item.image.hasOwnProperty('desktop') ? item.image.desktop : ''
          vm.coupon.isValid = true
        } else {
          vm.message = vm.$t('coupon_attach.message_error')
        }
      }, (error) => {
        let mess = error.error.message
        vm.message = mess
      })
    }
  }
}
</script>

<style scoped>

</style>
